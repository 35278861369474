













































































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";


@Component({
  components: {
    Header,
    Footer
  }
})
export default class Home extends Vue {
  introduceList: any[] = [
    {
      tit: "400",
      unit: "家",
      txt: "入驻企业"
    },
    {
      tit: "38768",
      unit: "人",
      txt: "二手车专业服务人员"
    },
    {
      tit: "765456",
      unit: "次",
      txt: "交易服务次数"
    },
    {
      tit: "354",
      unit: "小时",
      txt: "交易服务市场"
    }
  ];
  reportList: any[] = [
    {
      tit: "22项",
      txt: "简易报告"
    },
    {
      tit: "26项",
      txt: "标准报告"
    },
    {
      tit: "36项",
      txt: "详细报告"
    }
  ];
  bannerList: any[] = [
    require("../../assets/banner05.png"),
    require("../../assets/banner06.png"),
    // require("../../assets/banner03.jpg"),
    // require("../../assets/banner04.jpg")
  ];
  scrollReportBox = false;
  introduceList2: any[] = [
    {
      imgUrl:require('@/assets/tips1.png'),
      tips:'合法',
      introduce:'数据来源合法合规，信息脱敏规范使用，保护消费者和车商合法利益'
    },
    {
      imgUrl:require('@/assets/tips1.png'),
      tips:'权威',
      introduce:'中国汽车流通协会发起及建设，赋能二手车行业诚信体系建设'
    },
    {
      imgUrl:require('@/assets/tips1.png'),
      tips:'全面',
      introduce:'覆盖二手车交易全生命周期的数据和信息服务'
    },
    {
      imgUrl:require('@/assets/tips1.png'),
      tips:'高效',
      introduce:'快速、便捷、高质量的二手车信息服务，助力车商降本增效'
    }
  ]

  mounted() {
    this.$nextTick(() => {
      this.$refs[this.$route.params.href] &&
        window.scrollTo(
          0,
          (this.$refs[this.$route.params.href] as any).offsetTop
        );

      let y: any = null;

      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const reportOffsetTop =
        (this.$refs.serviceBox && (this.$refs.serviceBox as any).offsetTop) ||
        0;

      this.scrollReportBox = scrollTop >= reportOffsetTop - 100;

      window.onscroll = () => {
        if (!y) {
          y = window.pageYOffset;
        }
        const diffY = y - window.pageYOffset;

        if (diffY < 0) {
          const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          const reportOffsetTop =
            (this.$refs.serviceBox &&
              (this.$refs.serviceBox as any).offsetTop) ||
            0;

          this.scrollReportBox = scrollTop >= reportOffsetTop - 200;
        }
        y = window.pageYOffset;
      };
    });
  }

  changeLocation(el: any) {
    window.scrollTo(0, (this.$refs[el] as any).offsetTop);
  }
  lookProductIntroduce() {
    this.$router.push("/introduce");
  }
}
